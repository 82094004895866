"use client";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useSelectCsrfToken, useSelectLocale } from "@/hooks";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import "@/app/[lang]/styles/style.css";
import { DIGITS } from "@/utils/constants";
import { countriesEndpoint } from "@/services/client/endpoint";
import LoginMobilePhoneNumber from "./phone-number";
import LoginMobilePassword from "./password";

export default function LoginMobile({
    phonePrefix = "",
    login = "",
    password = "",
    setLoginDetails = function () {},
    errorMsg = "",
    setErrorMsg = function () {},
    content = {},
}) {
    const csrfToken = useSelectCsrfToken();
    const locale = useSelectLocale();
    const [phonePrefixes, setPhonePrefixes] = useState(
        content?.phonePrefixes || []
    );
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        async function getCountries() {
            const result = await sendInternalPostRequest({
                csrfToken,
                endpoint: countriesEndpoint,
            });
            if (
                Array.isArray(result?.countries) &&
                result?.countries?.length > DIGITS?.ZERO
            ) {
                const allowedPhonePrefixes = result?.countries || [];
                const validPhonePrefixes = [];
                content?.phonePrefixes?.map((phonePrefix) => {
                    const value =
                        phonePrefix?.value?.replace("-", "").trim() || "";
                    if (
                        allowedPhonePrefixes.some(
                            (_country) =>
                                _country?.phonePrefix
                                    ?.replace("-", "")
                                    ?.trim() === value && _country?.isActive
                        )
                    )
                        validPhonePrefixes.push(phonePrefix);
                });
                setPhonePrefixes(validPhonePrefixes);
            }
        }
        if (
            csrfToken?.length > DIGITS?.ZERO &&
            Array.isArray(content?.phonePrefixes) &&
            content?.phonePrefixes?.length > DIGITS?.ZERO
        )
            getCountries();
    }, [csrfToken, content?.phonePrefixes, setPhonePrefixes]);

    return (
        <div className="form__firstchoice">
            <LoginMobilePhoneNumber
                login={login}
                locale={locale}
                content={{
                    mobileRequired: content?.mobileRequired,
                    mobileLabel: content?.mobileLabel,
                    mobilePlaceholder: content?.mobilePlaceholder,
                }}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                setLoginDetails={setLoginDetails}
                phonePrefixes={phonePrefixes}
                phonePrefix={phonePrefix}
            />
            <LoginMobilePassword
                locale={locale}
                content={{
                    passwordRequired: content?.passwordRequired,
                    passwordLabel: content?.passwordLabel,
                    passwordPlaceholder: content?.passwordPlaceholder,
                }}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                setLoginDetails={setLoginDetails}
                setShowPassword={setShowPassword}
                password={password}
                showPassword={showPassword}
            />
        </div>
    );
}

LoginMobile.propTypes = {
    /**
     * Content data
     */
    content: PropTypes.object.isRequired,
    /**
     * Phone number prefix
     */
    phonePrefix: PropTypes.string.isRequired,
    /**
     * Mobile Number
     */
    login: PropTypes.string.isRequired,
    /**
     * Password
     */
    password: PropTypes.string.isRequired,
    /**
     * Set login details
     */
    setLoginDetails: PropTypes.func.isRequired,
    /**
     * Error message
     */
    errorMsg: PropTypes.string.isRequired,
    /**
     * lang
     */
    lang: PropTypes.string.isRequired,
};
