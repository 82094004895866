import { generateCartItem, gtmDateFormat } from "@/utils/functions.client";
import { trackCartEvents, trackClickEvent } from "@/utils/gtm";
import { INVALID_LOGIN, LOGIN_FAILED } from "@/utils/error-constants";
import { loginPath } from "@/services/client/endpoint";
import { DIGITS } from "@/utils/constants";

export function restrictedPaths(locale = "en") {
    return [
        `/${locale}/checkout/success`,
        `/${locale}/checkout/failed`,
        `/${locale}/add-credit-payment-success`,
        `/${locale}/withdraw-succes`,
        `/${locale}/my-account/verification`,
        `/${locale}/my-account/withdraw`,
        `/${locale}/my-account/verification-success`,
        `/${locale}/my-account/verification-rejected`,
        `/${locale}/create-account`,
    ];
}

export async function handleLoginSubmit({
    csrfToken = "",
    locale = "",
    drawDate = "",
    content = {},
    loginDetails = {},
    cartItems = [],
    gtmData = {},
    dispatchShowLoader = function () {},
    setErrorMsg = function () {},
    dispatchprevPath = function () {},
    dispatchCurrentPath = function () {},
    sendInternalPostRequest = function () {},
    redirect = function () {},
    dispatchSetAuthUser = function () {},
    dispatchSetBalance = function () {},
    dispatchShowToaster = function () {},
    errorMsg = "",
}) {
    const { type, phonePrefix, login, password, deviceType, platformType } =
        loginDetails;
    const prevURL = sessionStorage.getItem("prevPath");
    dispatchprevPath(prevURL);
    const currentURL = sessionStorage.getItem("currentPath");
    dispatchCurrentPath(currentURL);
    const isBrowser = typeof window !== "undefined";
    const isRechargeBalanceNav = isBrowser
        ? sessionStorage.getItem("isRechargeBalanceVisited")
        : null;

    if (
        ((type === "MobileNumber" && phonePrefix) || type) &&
        login?.length > DIGITS?.ZERO &&
        password?.length > DIGITS?.ZERO &&
        !errorMsg &&
        deviceType?.length > DIGITS?.ZERO &&
        platformType?.length > DIGITS?.ZERO
    ) {
        let msg = "";
        setErrorMsg("");
        dispatchShowLoader(true);

        try {
            const response = await sendInternalPostRequest({
                endpoint: loginPath,
                data: loginDetails,
                csrfToken,
            });
            if (response?.success && response?.loggedIn) {
                if (response?.RequirePasswordChange) {
                    redirect(
                        "/" + locale + "/forgot-password?RequirePasswordChange"
                    );
                } else {
                    if (response?.profile) {
                        dispatchSetAuthUser(response?.profile || {});

                        if (prevURL === "/en/cart" || prevURL === "/ar/cart") {
                            const itemVal = generateCartItem(
                                cartItems,
                                gtmDateFormat(drawDate || ""),
                                gtmData?.gameID || ""
                            );
                            trackCartEvents({
                                event: "add_user_info",
                                event_category: "ecommerce",
                                ecommerce: {
                                    currency: gtmData?.currency || "",
                                    value: itemVal?.value * 3000,
                                    user_id:
                                        response?.profile?.profile?.playerID ||
                                        gtmData?.userid,
                                    p_previouspagename:
                                        gtmData?.previousPage || "",
                                    items: itemVal?.item,
                                },
                            });
                        } else {
                            trackClickEvent(
                                "e_bodyinteraction",
                                "non_ecommerce",
                                "login",
                                "",
                                gtmData?.lang || "en",
                                gtmData?.currentPage || "",
                                gtmData?.category || "",
                                gtmData?.subcategory || "",
                                gtmData?.previousPage || "",
                                gtmData?.loggedIn || "",
                                response?.profile?.profile?.playerID ||
                                    gtmData?.userid
                            );
                        }
                    }
                    if (response?.balance?.currencyCode) {
                        dispatchSetBalance({
                            ...response.balance,
                        });
                    }

                    if (prevURL === "/" + locale + "/checkout/success") {
                        redirect("/" + locale);
                    } else if (
                        currentURL === prevURL &&
                        !isRechargeBalanceNav
                    ) {
                        redirect("/" + locale);
                    } else if (isRechargeBalanceNav) {
                        redirect("/" + locale + "/recharge-balance");
                    } else if (prevURL === "/" + locale + "/forgot-password") {
                        redirect("/" + locale + "/my-account/my-profile");
                    } else {
                        redirect(prevURL);
                    }
                }
            } else {
                msg = LOGIN_FAILED;
                if (response?.error && typeof response.error === "string") {
                    msg = content?.mobileLoginError || INVALID_LOGIN;
                } else if (
                    response?.error &&
                    typeof response.error === "object"
                ) {
                    msg = content?.mobileLoginError || INVALID_LOGIN;
                }
                setErrorMsg(msg);
                dispatchShowToaster(msg);
            }
        } catch (e) {
            dispatchShowToaster(e?.message || "");
        } finally {
            dispatchShowLoader(false);
        }
    }
}
