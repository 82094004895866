"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import { useState, useEffect } from "react";
import LoginMobile from "./mobile";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
    useDispatchSetAuthUser,
    useDispatchSetBalance,
    useDispatchShowLoader,
    useRedirect,
    useSelectAuthUser,
    useSelectCsrfToken,
    useSelectLocale,
    useSelectReadyStore,
    useDispatchShowToaster,
    useSelectCart,
    useSelectGrandDrawDate,
    useDispatchPrevPath,
    useDispatchCurrentPath,
    useSelectPrevPath,
    useSelectCurrentPath,
} from "@/hooks";
import {
    isMobile,
    isTablet,
    isMacOs,
    isWindows,
    getUA,
} from "react-device-detect";
import { DIGITS, STRING_CONSTANTS } from "@/utils/constants";
import { handleLoginSubmit, restrictedPaths } from "./functions";
import LoginError from "./login-error";
import { useSelector } from "react-redux";

export default function Login({ lang = "en", content = {} }) {
    const [loginDetails, setLoginDetails] = useState({
        type: "MobileNumber",
        phonePrefix:
            content?.defaultPrefix?.value || DIGITS?.DIGIT_964?.toString(),
        login: "",
        password: "",
        deviceType: isMobile
            ? STRING_CONSTANTS?.SMART_PHONE
            : isTablet
            ? STRING_CONSTANTS?.TABLET
            : STRING_CONSTANTS?.PC,
        platformType: isWindows
            ? STRING_CONSTANTS?.WINDOWS
            : isMacOs
            ? STRING_CONSTANTS?.MACOS
            : getUA
                  ?.toLowerCase()
                  ?.includes(STRING_CONSTANTS?.ANDROID?.toLowerCase())
            ? STRING_CONSTANTS?.ANDROID
            : STRING_CONSTANTS?.LINUX,
    });
    const [errorMsg, setErrorMsg] = useState("");
    const readyStore = useSelectReadyStore();
    const authUser = useSelectAuthUser();
    const csrfToken = useSelectCsrfToken();
    const locale = useSelectLocale();
    const dispatchShowLoader = useDispatchShowLoader();
    const dispatchSetAuthUser = useDispatchSetAuthUser();
    const dispatchSetBalance = useDispatchSetBalance();
    const dispatchShowToaster = useDispatchShowToaster();
    const redirect = useRedirect();
    const cartItems = useSelectCart();
    const drawDate = useSelectGrandDrawDate();
    const dispatchprevPath = useDispatchPrevPath();
    const getCurrentPath = useSelectCurrentPath();
    const getPrevPath = useSelectPrevPath();
    const dispatchCurrentPath = useDispatchCurrentPath();
    const gtmData = useSelector((state) => state?.authUser?.data);
    const isBrowser = typeof window !== "undefined";
    const mob_Link = isBrowser ? sessionStorage.getItem("mb_ftr_nav") : null;

    useEffect(() => {
        if (
            readyStore &&
            authUser?.profile?.dateOfBirth?.length > DIGITS?.ZERO
        ) {
            const prevPath = getPrevPath;
            const currentPath = getCurrentPath;
            const restricted = restrictedPaths(locale);

            if (restricted.includes(prevPath)) {
                redirect(`/${locale}`);
            } else if (mob_Link) {
                redirect(mob_Link);
            } else if (currentPath === prevPath && !mob_Link) {
                redirect(`/${locale}`);
            } else if (prevPath === "/" + locale + "/forgot-password") {
                redirect("/" + locale + "/my-account/my-profile");
            } else {
                redirect(prevPath);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser, redirect, readyStore]);

    return (
        <section className="login login__new">
            <div className="container">
                <div className="login__content">
                    <div className="form">
                        <h1 className="ffv fs24">{content?.title || ""}</h1>
                        <form
                            id="form-login"
                            method="POST"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleLoginSubmit({
                                    loginDetails,
                                    csrfToken,
                                    drawDate,
                                    getCurrentPath,
                                    getPrevPath,
                                    errorMsg,
                                    cartItems,
                                    gtmData,
                                    sendInternalPostRequest,
                                    dispatchShowLoader,
                                    dispatchSetAuthUser,
                                    dispatchSetBalance,
                                    dispatchShowToaster,
                                    redirect,
                                    dispatchprevPath,
                                    dispatchCurrentPath,
                                    setErrorMsg,
                                    content,
                                });
                            }}
                        >
                            <LoginMobile
                                phonePrefix={loginDetails.phonePrefix}
                                login={loginDetails.login}
                                password={loginDetails.password}
                                setLoginDetails={setLoginDetails}
                                errorMsg={errorMsg}
                                setErrorMsg={setErrorMsg}
                                content={{
                                    mobileLabel: content?.mobileLabel || "",
                                    mobileRequired: content?.mobileRequired
                                        ? true
                                        : false,
                                    mobilePlaceholder:
                                        content?.mobilePlaceholder || "",
                                    passwordLabel: content?.passwordLabel || "",
                                    passwordRequired: content?.passwordRequired
                                        ? true
                                        : false,
                                    mobileLoginError:
                                        content?.mobileLoginError || "",
                                    phonePrefixes: content?.phonePrefixes || [],
                                }}
                                lang={lang}
                            />
                            <Link
                                className="ffv forgot-pw text-orange"
                                href={content?.forgotPassword?.link || "#"}
                            >
                                {content?.forgotPassword?.text || ""}
                            </Link>
                            <button
                                className={
                                    "btn btn--primary bold-iq" +
                                    (errorMsg ||
                                    !loginDetails?.login ||
                                    !loginDetails?.password
                                        ? " disabled"
                                        : "")
                                }
                                type="submit"
                            >
                                {content?.login?.text || ""}
                            </button>
                            <Link
                                href={content?.createAccount?.link || "#"}
                                className="ffv login__content--link text-orange"
                            >
                                {content?.createAccount?.text || ""}
                            </Link>
                            <LoginError
                                content={{
                                    loginErrorMessage:
                                        content?.loginErrorMessage,
                                    signUp: content?.signUp || {},
                                }}
                                errorMsg={errorMsg}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

Login.propTypes = {
    /**
     * Current language
     */
    lang: PropTypes.string.isRequired,
    /**
     * Content data
     */
    content: PropTypes.object.isRequired,
};
